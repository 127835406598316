<template>
  <div>
    <Page :tabList="tabList"></Page>
  </div>
</template>

<script>
import Page from "../../components/public/Page.vue";
export default {
  components: {
    Page,
  },
  data() {
    return {
      tabList: [
        // { name: "同步OA组织结构", path: "/oa-structure" },
        { name: "职能管理", path: "/function-manage" },
        { name: "公司部门设置", path: "/Company_Dept" },
        { name: "岗位设置", path: "/Post_setting" },
        { name: "人员设置", path: "/Person_Setting" },
        { name: "过程设置", path: "/Process_setting" },
        { name: "角色权限设置", path: "/role-permissions" },
        { name: "岗位与职能维护", path: "/job-fun-maintain" },
        { name: "个人中心", path: "/change-password" },
        // { name: "设置时间监控", path: "/time-monitoring" },
        { name: "申请审批管理", path: "/approval-application" },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style>
</style>